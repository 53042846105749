


















/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';

@Component
export default class Progress extends Vue {
  @Prop({ type: Number, default: 0 }) readonly value!: number
  @Prop({ type: String, default: null }) readonly text!: string
  @Prop({ type: String, default: '#F4BC46' }) readonly color!: string
  @Prop({ type: Boolean, default: false }) readonly dark!: boolean

  mounted() {
    console.log('Progress: mounted()'); // eslint-disable-line no-console
  }
}
